import React, { useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";

const RequestPercentageModal = ({ show, handleClose, data }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="p-3">
        <h4 className="pl-3 m-0">Requests Percentage</h4>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <div>
          <div className="d-flex justify-content-between align-items-start pb-2">
            <th>Request Name</th>
            <th>Proportion (%)</th>
          </div>
          <div>
            {Object.entries(data)?.map(([name, proportion], index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-start"
              >
                <td className="font-w500">{name}</td>
                <td style={{ width: "30%" }}>
                  <span className="percentage-bar">{proportion} %</span>
                </td>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestPercentageModal;
