import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { approveRejectRequest } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";

const ReasonModal = ({ show, id, type, table, onHide }) => {
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({ reason: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleHide = () => {
    setReason("");
    setErrors({ reason: "" });
    onHide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const payload = {
          zapperId: id,
          status: "Rejected",
          reason: reason,
        };
        await approveRejectRequest(payload);
        notifyTopRight("Rejected Successfully.");
        table(); // Refresh the table
        handleHide(); // Close the modal and reset state
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          (error.request
            ? "Network error. Please try again."
            : "An error occurred");
        notifyError(errorMessage);
        console.error("Error Details:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errorObj = { reason: "" };

    if (!reason.trim()) {
      errorObj.reason = "Reason is required";
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };

  const modalTitle = type === "reject" ? "Reject Request" : "Provide Reason";

  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
      aria-labelledby="reason-modal-title"
      role="dialog"
    >
      <form onSubmit={handleSubmit}>
        <div id="reason-modal-title" className="modal-header">
          <h4 className="modal-title fs-20">{modalTitle}</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Reason</label>
            <textarea
              rows={5}
              className="form-control"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrors({ ...errors, reason: "" });
              }}
              required
            />
            {errors.reason && (
              <div className="text-danger fs-12">{errors.reason}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={handleHide}
            className="btn btn-danger btn-sm"
          >
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Rejecting..." : "Reject"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReasonModal;
