import moment from "moment";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function getFormatedGraphData(data, filterType) {
  console.log(data, "data 2343");
  let labels, plottingData;
  if (data?.length === 0) return { labels: [], data: [] };

  plottingData = data?.map((item) => item.total);

  if (filterType === "week") {
    // Assuming data has dates like data[i].date (from the API response)
    labels = data.map((item) => moment(item.date).format("dddd")); // Get day names for the last 7 days
    return {
      labels: labels.reverse(),
      data: plottingData.reverse(),
    };
  } else if (filterType === "year") {
    return {
      labels: [...months], // Assuming months is defined elsewhere
      data: plottingData,
    };
  } else if (filterType === "custom") {
    if (data?.length < 7) {
      return {
        labels: [...weekDays],
        data: plottingData,
      };
    } else {
      return {
        labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
        data: convertToWeeklyData(plottingData),
      };
    }
  } else {
    return {
      labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
      data: convertToWeeklyData(plottingData),
    };
  }
}
function convertToWeeklyData(dailyData) {
  const weeklyData = [];
  let currentWeek = [];

  dailyData.forEach((day, index) => {
    currentWeek.push(day);
    // Check if we have completed a week or reached the end of the data
    if ((index + 1) % 7 === 0 || index === dailyData.length - 1) {
      weeklyData.push([...currentWeek]);
      currentWeek = [];
    }
  });
  let weekTotalDataArray = weeklyData.map((item) => {
    return item.reduce((sum, el) => sum + el, 0);
  });
  // console.log(weekTotalDataArray, "weekly data");
  return weekTotalDataArray;
}
export function getFormatedPieChatData(record) {
  const data = [];
  const labels = [];

  Object.entries(record).forEach(([label, value]) => {
    labels.push(label.toLowerCase());
    data.push(value);
  });

  return { data, labels };
}

export const getRandomColorsArray = (count, opacity) => {
  const backgrounds = [];
  const borders = [];

  const getRandomColorValue = () => Math.floor(Math.random() * 256);

  for (let i = 0; i < count; i++) {
    const red = getRandomColorValue();
    const green = getRandomColorValue();
    const blue = getRandomColorValue();

    const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${opacity})`; // Adding template literals
    backgrounds.push(rgbaColor);

    const rgbaBorderColor = `rgba(${red}, ${green}, ${blue}, 0.6)`; // Adding template literals
    borders.push(rgbaBorderColor);
  }

  return { backgrounds, borders };
};
