export const GET_USERS = 'admin/users';

export const DELETE_USERS = 'admin/deleteUser';

export const BLOCK_USERS = 'admin/userAction';

export const APPROVE_USER_DETAILS = 'admin/auth/userApproved';

export const VERIFICATION_LIST = "admin/zapperRequest";

export const VERIFICATION_LIST_ACTION = "admin/actionOnZapperRequest";

export const GET_CODES = 'admin/zipCodeList';

export const DELETE_CODE = 'admin/deleteZipCode';

export const ADD_CODE = 'admin/createZipCode';

export const USER_GRAPH = 'admin/userGraphData';

export const BOOKING_GRAPH = 'admin/userRequestsData';