import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  deleteUser,
  getAllUsers,
} from "../../services/User/UserService";
import { useDispatch } from "react-redux";
import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";
import ZapperList from "./ZapperList";
import RequestList from "./RequestList";

export default function ZapperManagement(props) {
  const [selectedTab, setSelectedTab] = useState("requests");
  const tabData = [
    { name: "Requests", component: <RequestList /> },
    { name: "Zapper", component: <ZapperList /> },
  ];
  return (
    <div>
      <PageTitle activeMenu="Zapper List" motherMenu="Zappers" />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={selectedTab}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-3">
                {tabData.map((data, i) => (
                  <Tab.Pane
                    eventKey={data?.name.toLowerCase()}
                    onClick={() => setSelectedTab(data.name.toLowerCase())}
                    key={i}
                  >
                    {data?.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
      {/* <Card>
        <Card.Header className="d-block">
          <div className="d-flex justify-content-between align-items-center">
            <div className="col-8" style={{ flexGrow: 1 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="input-group border bg-white input-group-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <input
                    type="text"
                    name="table_search"
                    className="form-control float-right border-0 search-input"
                    placeholder="Search"
                    // onKeyDown={(e) => {
                    //   console.log(e.key);
                    //   if (e.key === "Enter") {
                    //     handleFetch();
                    //     // setCurrentPage(0);
                    //   }
                    // }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={getTableData}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>

                  <th>
                    <strong>STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <img
                        src={item.image ? item.image : profile}
                        width={70}
                        height={70}
                      />
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>
                      {" "}
                      {item?.countryCode ? "+" + item?.countryCode : ""}
                      {item?.phoneNumber}
                    </td>

                    <td>
                      {item?.isBlocked ? (
                        <Badge variant="danger light">Disabled</Badge>
                      ) : (
                        <Badge variant="success light">Enabled</Badge>
                      )}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dark light"
                          className="light sharp btn btn-dark i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {item?.isBlocked ? (
                            <Dropdown.Item onClick={() => onAction(item?._id)}>
                              Enable
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => onAction(item?._id)}>
                              Disable
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item onClick={() => onDelete(item?._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card> */}
    </div>
  );
}
