import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({ pageHeading, motherMenu, activeMenu }) => {
  let path = window.location.pathname.split("/");
  console.log(path);

  return (
    <div className="page-titles">
      <h4>
        {activeMenu}
        {pageHeading}
      </h4>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">{motherMenu}</li>
        <li className="breadcrumb-item active">{activeMenu}</li>
      </ol>
    </div>
  );
};

export default PageTitle;
