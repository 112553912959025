import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { changePasswoard } from "../../services/AuthService";

function ChangePassword({ password, onHide }) {
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [apiError, setApiError] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleInputChange = (field, value) => {
    // Update the state for the specific field
    if (field === "oldPassword") setOldPassword(value);
    if (field === "newPassword") setNewPassword(value);
    if (field === "confirmPassword") setConfirmPassword(value);

    // Reset the error for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    let error = false;
    const newErrors = { oldPassword: "", newPassword: "", confirmPassword: "" };

    if (oldPassword === "") {
      newErrors.oldPassword = "Old Password is Required";
      error = true;
    }
    if (newPassword === "") {
      newErrors.newPassword = "New Password is Required";
      error = true;
    }
    if (confirmPassword === "") {
      newErrors.confirmPassword = "Confirm Password is Required";
      error = true;
    }
    if (oldPassword === newPassword && oldPassword !== "") {
      newErrors.newPassword = "Please create a new password";
      error = true;
    }
    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Password does not match";
      error = true;
    }

    setErrors(newErrors);
    if (error) return;

    changePasswoard(oldPassword, newPassword)
      .then(() => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        onHide();
        notifyTopRight("Updated Successfully.");
      })
      .catch((error) => {
        console.error(error.response, "Change password error");
        notifyError(error.response.data.message);
      });
  };

  return (
    <>
      <Modal className="modal fade" show={password} centered>
        <form onSubmit={onChangePassword}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Change Password</h4>
          </div>
          <div className="modal-body">
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
            <div className="form-group mb-3">
              <label className="text-black font-w500">Old Password</label>
              <input
                type="password"
                className="form-control"
                autoComplete="off"
                value={oldPassword}
                onChange={(e) =>
                  handleInputChange("oldPassword", e.target.value)
                }
              />
              {errors.oldPassword && (
                <div className="text-danger fs-12">{errors.oldPassword}</div>
              )}
            </div>
            <div className="form-group mb-3">
              <label className="text-black font-w500">New Password</label>
              <input
                type="password"
                className="form-control"
                autoComplete="off"
                value={newPassword}
                onChange={(e) =>
                  handleInputChange("newPassword", e.target.value)
                }
              />
              {errors.newPassword && (
                <div className="text-danger fs-12">{errors.newPassword}</div>
              )}
            </div>
            <div className="form-group mb-3">
              <label className="text-black font-w500">
                Confirm New Password
              </label>
              <input
                type="password"
                className="form-control"
                autoComplete="off"
                value={confirmPassword}
                onChange={(e) =>
                  handleInputChange("confirmPassword", e.target.value)
                }
              />
              {errors.confirmPassword && (
                <div className="text-danger fs-12">
                  {errors.confirmPassword}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              onClick={() => onHide()}
              className="btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  errorMessage: state.auth.errorMessage,
  successMessage: state.auth.successMessage,
  showLoading: state.auth.showLoading,
});

export default connect(mapStateToProps)(ChangePassword);
