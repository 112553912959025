import React, { useState } from "react";
import "./ZapperDetails.css"; // Custom CSS file
import moment from "moment";
import ShowImage from "../modal/showDocModal";
import PageTitle from "../layouts/PageTitle";
import defaultImage from "../../images/user-icon3.png";

const ZapperDetails = (props) => {
  const data = props.location.state;
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  console.log(data);
  const onImageError = (e) => {
    e.target.src = defaultImage;
  };
  function prevImage(imageUrl) {
    setShowImage(true);
    setSelectedImage(imageUrl);
  }
  return (
    <>
      <PageTitle activeMenu="Zapper Details" motherMenu="Zapper Management" />
      <div className="details-page">
        <div className="details-container">
          {/* Left Section */}
          <div className="left-section">
            <img
              src="https://v.com/100" // Replace with actual profile image URL
              alt="Profile"
              className="profile-img"
              onError={onImageError}
            />
            <h2 className="name m-0">{data?.name}</h2>
            <p className="member-since m-0">
              Last seen: {moment(data?.lastSeen).format("lll")}
            </p>
            <p className="designation m-0">Ratings:{data?.rating}</p>
            <p className="designation m-0">{data?.type}</p>

            <div className="doc-section">
              <h3>Documents</h3>
              <div className="docs">
                {data?.documents.map((doc) => (
                  <img
                    className="doc-img"
                    src={doc.file}
                    onClick={() => prevImage(doc.file)}
                    onError={onImageError}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="right-section">
            {/* Official Information */}
            <div className="info-section">
              <h3>Official Information</h3>
              <div className="info-row pb-2">
                <div>
                  <strong>Email</strong>
                  <p>{data?.email}</p>
                </div>
                <div>
                  <strong>Phone</strong>
                  <p>
                    +{data.countryCode} {data.phoneNumber}
                  </p>
                </div>
              </div>
              <div className="full-width pb-2">
                <strong>Address</strong>
                <p>{data.address}</p>
              </div>
            </div>

            {/* Personal Information */}
            <div className="info-section">
              <h3>Personal Information</h3>
              <div className="">
                <div className="d-flex align-items-center">
                  <strong>Date Of Birth: </strong>
                  <p className="ml-2">
                    {moment(data?.dateOfBirth).format("ll")}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <strong>Receive Service : </strong>
                  <p className="ml-2">{data.receiveService}</p>
                </div>
                <div className="d-flex align-items-center">
                  <strong>Security Number : </strong>
                  <p className="ml-2">{data.securityNumber}</p>
                </div>
                <div className="d-flex align-items-center pb-2">
                  <strong>Total Balance: </strong>
                  <p className="ml-2">{data.totalBalance}</p>
                </div>
                {/* <div>
                <strong>Total Balance</strong>
                <br />
                <p className="text-center">{data.totalBalance}</p>
              </div> */}
              </div>
            </div>

            {/* Tags Section */}
            <div className="tags-section">
              <h3>Expertise</h3>
              <div className="tags-container">
                {data.expertise.map((tag, idx) => (
                  <div key={idx} className="tag">
                    {tag} <span className="close-icon"></span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <ShowImage
          show={showImage}
          onHide={() => setShowImage(false)}
          image={selectedImage}
        />
      </div>
    </>
  );
};

export default ZapperDetails;
