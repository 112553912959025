import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { addCode } from "../../services/User/UserService";

export default function AddCode({ show, table, onHide }) {
  const [code, setCode] = useState([]);
  const [newText, setNewText] = useState("");
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({ code: "" });
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const handleAddText = (e) => {
    e.preventDefault();
    if (newText.trim()) {
      setCode((prev) => [...prev, newText.trim()]);
      setNewText("");
    }
  };

  const handleUpdateText = () => {
    const updatedCode = [...code];
    updatedCode[currentEditIndex] = newText.trim();
    setCode(updatedCode);
    setNewText("");
    setIsEdit(false);
    setCurrentEditIndex(null);
  };

  const handleEdit = (index, item) => {
    setNewText(item);
    setCurrentEditIndex(index);
    setIsEdit(true);
  };

  const handleDelete = (index) => {
    setCode((prev) => prev.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    if (code.length === 0) {
      setErrors({ code: "This field is required" });
      return false;
    }
    setErrors({ code: "" });
    return true;
  };

  const onSubmitCode = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true); // Start loader
    try {
      const formattedCodes = code.map((item) => ({ code: item }));
      const response = await addCode(formattedCodes);
      notifyTopRight(response.data?.message || "Codes added successfully!");
      table(); // Refresh the table
      onHide(); // Close the modal
      setCode([]);
    } catch (error) {
      notifyError(error.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <form onSubmit={onSubmitCode}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add Code</h4>
        </div>
        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="form-group mb-3">
                <label className="text-black font-w500">Code</label>
                {code.length > 0 && (
                  <ul className="ml-4 mb-2">
                    {code.map((item, index) => (
                      <div
                        className="d-flex justify-content-between"
                        key={index}
                      >
                        <li>{item}</li>
                        <div>
                          <i
                            className="flaticon-381-edit-1 pointer"
                            onClick={() => handleEdit(index, item)}
                          ></i>
                          <i
                            className="flaticon-381-trash-1 pointer ml-2"
                            onClick={() => handleDelete(index)}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </ul>
                )}
                <div className="contact-name d-flex">
                  <input
                    type="text"
                    className="form-control mr-2"
                    value={newText}
                    onChange={(e) => setNewText(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary btn-color py-1 px-3"
                    onClick={isEdit ? handleUpdateText : handleAddText}
                  >
                    {isEdit ? "Update" : "Add"}
                  </button>
                </div>
                {errors.code && (
                  <div className="text-danger fs-12">{errors.code}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={onHide}
            disabled={isLoading}
          >
            <i className="flaticon-delete-1"></i> Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
