import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { connect } from "react-redux";
import moment from "moment";
function UserDetails(props) {
  const [modal, setModal] = useState(false);
  const userData = props.currentData;
  const image = props.currentData.fitnessCertificate;
  return (
    <div>
      <div className="page-titles">
        <h4>User Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="user-management">User Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>User Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "70%" }}>
        <Card.Header className="d-block">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <div>
              <img
                src={userData.profile}
                width={100}
                height={100}
                className="text-center"
              />
            </div>
            <div className="text-left">
              <h3 className="text-black ">{userData.fName} {userData.lName}</h3>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Date of Birth</h5>
              <p className="">{moment(userData.dob).format("DD/MM/YYYY")}</p>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Address</h5>
              <p className="">
                {userData?.address ? (
                  (userData?.address?.address,
                  userData?.address?.city,
                  userData?.address?.country)
                ) : (
                  <span className="text-danger">Data not provided!</span>
                )}
              </p>
            </div> */}
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Number Of Certification</h5>
              <p className="">{userData?.noOfCertificate}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Client</h5>
              <p className="">{userData.clients}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Type Of Trainer</h5>
              <p className="w-50 text-right">{userData.typeOfTrainer.map((item)=>(
                item + ","
              ))}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Training Location</h5>
              <p className=" w-50 text-right">{userData.trainningLocation.map((item)=>(
                item + ","
              ))}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Services</h5>
              <p className="w-50 text-right">{userData.services.map((item)=>(item +","))}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Years In Business</h5>
              <p className="">{userData.years}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Fitness Certificate</h5>
              {/* <p className="">5</p> */}
              
            </div>
          </div>
        </Card.Body>
      </Card>
     
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentData: state.UserData.currentData,
  };
};
export default connect(mapStateToProps)(UserDetails);
