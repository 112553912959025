import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  deleteUser,
  getAllUsers,
} from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ZapperList = (props) => {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState("Zapper");
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllUsers(currentPage, limit, search, type);
      console.log(response.data.data.users);
      setUsers(response.data.data.users);
      const total = response.data.data?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data?.total);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  ///function to delete data ///
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      getTableData();
      notifyTopRight("Deleted Successfully!");
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, search]);
  return (
    <>
      <div className="d-block justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-6">
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={getTableData}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <Spinner />
      ) : (
        <Table responsive>
          <thead style={{ color: "black" }}>
            <tr>
              <th>
                <strong>NAME</strong>
              </th>
              <th>
                <strong>EMAIL</strong>
              </th>

              <th>
                <strong>PHONE NUMBER</strong>
              </th>

              <th>
                <strong>STATUS</strong>
              </th>
              <th>
                <strong>created at</strong>
              </th>
              <th>
                <strong>ACTION</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((item, i) => (
              <tr key={i} className={item.isDeleted ? "disabled-row" : ""}>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>
                  {item.countryCode && "+" + item.countryCode}{" "}
                  {item?.phoneNumber}
                </td>

                <td>
                  {item?.isBlocked ? (
                    <Badge bg="" className="badge-danger">
                      Disabled
                    </Badge>
                  ) : (
                    <Badge bg="" className="badge-success">
                      Enabled
                    </Badge>
                  )}
                </td>
                <td>{moment(item?.createdAt).format("MMM Do YYYY, h:mm A")}</td>
                <td>
                  {!item?.isDeleted && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="dark light"
                        className="light sharp btn btn-dark i-false"
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: "/zapper-details",
                              state: item,
                            })
                          }
                        >
                          Details
                        </Dropdown.Item>
                        {item.isBlocked ? (
                          <Dropdown.Item onClick={() => onAction(item._id)}>
                            Enable
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item onClick={() => onAction(item._id)}>
                            Disable
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => onDelete(item._id)}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {users?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
      {users?.length !== 0 && (
        <div className="card-footer clearfix">
          <div className="d-block d-sm-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {currentPage * limit + 1} to{" "}
              {listLength > (currentPage + 1) * limit
                ? (currentPage + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ZapperList;
